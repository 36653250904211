<template>
    <div class="view-app">
        <sidebar-view>
            <dashboard-container>
                <div class="card mb-7">
                    <div class="card-body">
                        <h2 class="mb-4">Frequently Asked Questions</h2>
                        <FAQBase />
                        <p class="lead mt-5">
                            Still have a question?
                            <a
                                href="javascript:;"
                                @click="$router.push({ name: 'Support' })"
                                >Contact us</a
                            >.
                        </p>
                    </div>
                </div>
            </dashboard-container>
        </sidebar-view>
    </div>
</template>

<script>
import sidebarView from './sidebar/sidebar';
import dashboardContainer from './dashboard_container';
import FAQBase from '@/views/faq';

export default {
    components: {
        FAQBase,
        sidebarView,
        dashboardContainer,
    },
};
</script>
